import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { useNavigate } from 'react-router-dom';
import hotCheetoes from '../assets/hotCheetoes.png';
import hungerGames from '../assets/hungerGames.jpg';
import padThai from '../assets/padThai.webp';
import lakeTahoes from '../assets/lakeTahoe.jpg';
import questionMark from '../assets/questionMark.png';

export default function Mariana() {

    const [foodCorrect, setfoodCorrect] = useState(false);
    const [movieCorrect, setmovieCorrect] = useState(false);
    const [snackCorrect, setsnackCorrect] = useState(false);
    const [placeToHangoutCorrect, setplaceToHangoutCorrect] = useState(false);

    const [inputValue, setInputValue] = useState('');  // State to store the input value
    const [message, setMessage] = useState(''); 
    const [wrong, setWrong] = useState(false); 

    const checkInput = () => {
        if (inputValue === '') {
            setWrong(true);
        } if (!foodCorrect) {
            if (inputValue.toLowerCase().includes('thai')) {
                setWrong(false)
                setfoodCorrect(true)
            } else {
                setWrong(true)
            }
        } else if (!movieCorrect) {
            if (inputValue.toLowerCase().includes('hunger games') || inputValue.toLowerCase().includes('the ballad of songbird')) {
                setWrong(false)
                setmovieCorrect(true)
            } else {
                setWrong(true)
            }
        } else if (!snackCorrect) {
            if (inputValue.toLowerCase().includes('cheetos')) {
                setWrong(false)
                setsnackCorrect(true)
            } else {
                setWrong(true)
            }
        } else if (!placeToHangoutCorrect) {
            if (inputValue.toLowerCase().includes('lake')) {
                setWrong(false)
                setplaceToHangoutCorrect(true)
            } else {
                setWrong(true)
            }
        }

    };

    return (
        <div style={{ height: '100vh', width: '100vw', backgroundColor: 'purple' }}>
            <div
                style={{
                    fontSize: '50px',
                    color: 'white',
                }}
            >
                Mariana Quiz
            </div>
            <div style={{color: 'white', textAlign: 'center'}}>
            <div>
                {!foodCorrect ? (<>
            What's her favorite food?</>) : 
            (

                <>
                            {!movieCorrect ? (<>
                What's her favorite movie?</>) : (

                    <>
                {!snackCorrect ? (<>
                    What's her favorite snack?</>) : (

                        <>
                    {!placeToHangoutCorrect && (<>
                        What's her favorite place to hangout?</>)}
                        </>
                    )}


                    </>
                )}


                </>
            )}
            

            </div>
            </div>
            <div style={{ textAlign: 'center' }}>
            <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}  // Update input value on change
                placeholder="Enter something"
                style={{ padding: '10px', fontSize: '16px', border: wrong ? '4px solid red' : '4px solid transparent' }}
            />
            <button 
                onClick={checkInput}
                style={{ marginLeft: '10px', padding: '10px', fontSize: '16px' }}
            >
                Check
            </button>
            <div style={{ marginTop: '20px', fontSize: '18px', color: 'red' }}>
                {message}  {/* Display the validation message */}
            </div>
        </div>

            <div style={{display:'flex', flexDirection: 'row', width: '100vw'}}>
            <img src = {foodCorrect ? padThai: questionMark} style={{height: 'auto', width: '25vw'}}/>
            <img src = {movieCorrect ? hungerGames: questionMark} style={{height: 'auto', width: '25vw'}}/>
            <img src = {snackCorrect ? hotCheetoes: questionMark} style={{height: 'auto', width: '25vw'}}/>
            <img src = {placeToHangoutCorrect ? lakeTahoes: questionMark} style={{height: 'auto', width: '25vw'}}/>
            </div>
        </div>
    );
};