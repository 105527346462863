import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { useNavigate } from 'react-router-dom';
import GavinM from '../assets/GavinM.jpg';
import GavinM2 from '../assets/GavinM2.png';
import appleIcon from '../assets/appleIcon.png';
import typeScriptLogo from '../assets/typescript.png';
import javascriptLogo from '../assets/javascriptLogo.png';
import kotlinLogo from '../assets/kotlinLogo.png';
import swiftLogo from '../assets/swiftLogo.png';
import blockchainIcon from '../assets/blockchainLogo.png';
import htmlLogo from '../assets/htmlLogo.png';
import androidLogo from '../assets/androidLogo.png';
import solanaLogo from '../assets/solanaLogo.png';
import metamaskLogo from '../assets/metamaskLogo.png';
import cPlusPlusLogo from '../assets/cPlusPlusLogo.webp';
import awsLogo from '../assets/awsLogo.png';
import reactLogo from '../assets/reactLogo.png';
import pythonLogo from '../assets/pythonLogo.png';
import adobeLogo from '../assets/adobeLogo.png';
import CompanySelector from '../components/companySelector';
import Education from '../components/education';

import Contact from '../components/contact';
import instagram from '../assets/instagram.png';
import github from '../assets/github.png';
import linkedin from '../assets/linkedin.webp';
import figmaLogo from '../assets/figmaLogo.png';
import bitcoin from '../assets/bitcoin.png';
import javaLogo from '../assets/javaLogo.png';
import designIcon from '../assets/designIcon.png';
import dockerLogo from '../assets/dockerLogo.png';
import Navigation from '../components/navigation';

export default function Home() {

    const isSmallScreen = window.innerWidth <= 700;

    const navigate = useNavigate();
    
    const [showToolBeltToolTip, setshowToolBeltToolTip] = useState(false);

    const [fontSize, setFontSize] = useState(1);  // Initial font size
    const isMobile = window.innerWidth <= 768;

    const [nameClass, setNameClass] = useState('hidden');
    const [imageClass, setImageClass] = useState('hidden');

    useEffect(() => {
        // Trigger the animations when the component mounts
        setTimeout(() => {
            setNameClass('slide-in-left');
            setImageClass('slide-in-right');
        }, 100); // Small delay to start the animation
    }, []);






    const [scrollPosition, setScrollPosition] = useState(1);
    const [changingScrollPosition, setChangingScrollPosition] = useState(false);
    const positions = 5; // Total number of scroll positions
  
    useEffect(() => {




        if (!isSmallScreen) {

            /*
            if (scrollPosition == 5) {
                let targetScrollY = document.documentElement.scrollHeight - window.innerHeight
            window.scrollTo({
              top: targetScrollY,
              behavior: 'smooth',
            });
            return
            }*/

      const handleWheel = (event: any) => {
        // Prevent the default scroll behavior
        event.preventDefault();

        // If already changing scroll position, do nothing
        if (changingScrollPosition) return;
  

        // Set the changingScrollPosition flag to true to prevent additional scroll events
        setChangingScrollPosition(true);
  
        // Detect scroll direction and update the scroll position accordingly
        if (event.deltaY > 0 && scrollPosition < positions) {
          // Scrolling down, increment position
          setScrollPosition((prev) => Math.min(prev + 1, positions));
        } else if (event.deltaY < 0 && scrollPosition > 1) {
          // Scrolling up, decrement position
          setScrollPosition((prev) => Math.max(prev - 1, 1));
        }
      };
  

      // Attach the wheel event listener
      window.addEventListener('wheel', handleWheel, { passive: false });
  
      
      console.log('scrollPositio', scrollPosition)
      // Scroll to the calculated position whenever scrollPosition changes
      let addition = 0;
      if (window.innerHeight < 700) {

        if (scrollPosition == 2) {
            addition = 50;
        }

        if (scrollPosition == 3) {
            addition = 60;
        }
        if (scrollPosition == 4) {
            addition = 80;
        }

      } else {
        if (scrollPosition == 4) {
            addition = -60;
        }
      }

      
      
      let targetScrollY;
        targetScrollY = ((scrollPosition - 1) * window.innerHeight) + addition;

      console.log('targetScrollY', targetScrollY)
      window.scrollTo({
        top: targetScrollY,
        behavior: 'smooth',
      });

      // After the scroll completes, reset the changingScrollPosition flag after a timeout
      const scrollTimeout = setTimeout(() => {
        setChangingScrollPosition(false);
      }, 1400); // Adjust the timeout as needed
  
      // Cleanup the event listener and timeout on component unmount
      return () => {
        window.removeEventListener('wheel', handleWheel);
        clearTimeout(scrollTimeout);
      };
    }
    }, [scrollPosition, changingScrollPosition]);



    useEffect(() => {
        const handleScroll = () => {

            if (isSmallScreen) {
                // Calculate new font size based on scroll position (adjust logic as needed)
                let newFontSize = 1 + window.scrollY * 0.05;  // grows 0.04px for every 1px of scroll
            
                newFontSize = Math.min(newFontSize, 23);
            
                setFontSize(newFontSize);
            } else {
                if (scrollPosition <= 2) {
                // Calculate new font size based on scroll position (adjust logic as needed)
                let newFontSize = 20 + window.scrollY * 0.05;  // grows 0.05px for every 1px of scroll

                // Ensure font size doesn't exceed 35px
                newFontSize = Math.min(newFontSize, 55);
                    console.log('setting less than max scroll', scrollPosition)
                    setFontSize(newFontSize);
                } else {
                    setFontSize(55);
                    console.log('setting max scroll', scrollPosition)
                }
            }

        };
    
        // Add the event listener
        window.addEventListener('scroll', handleScroll);
        
        // Cleanup
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPosition]);



  /*
  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };

  */



  return (
      
    <div>

{!isSmallScreen && (
<div style={{overflow: 'hidden', 
width: '100vw', 
    overflowX: 'hidden',
    overflowY: 'hidden'}}>
                <div className='homepage-animation-square-one'
                ></div>
                <div className='homepage-animation-square-two'
                ></div>
                <div className='homepage-animation-square-three'
                ></div>
                <div className='homepage-animation-square-three-dot-one'
                ></div>

                <div className='homepage-animation-square-four'
                ></div>
                <div className='homepage-animation-square-five'
                ></div>
                <div className='homepage-animation-square-six'
                ></div>

                <div className='homepage-animation-square-seven'
                ></div>
                <div className='homepage-animation-square-eight'
                ></div>
                <div className='homepage-animation-square-nine'
                ></div>
                <div className='homepage-animation-square-ten'
                ></div>
                <div className='homepage-animation-square-eleven'
                ></div>
                <div className='homepage-animation-square-twelve'
                ></div>
                <div className='homepage-animation-square-thirteen'
                ></div>
                <div className='homepage-animation-square-fourteen'
                ></div>
                <div className='homepage-animation-square-fifteen'
                ></div>
                <div className='homepage-animation-square-sixteen'
                ></div>
                <div className='homepage-animation-square-one-dot-one'
                ></div>
                </div>


)}

        <Navigation changingScrollPosition={changingScrollPosition} 
        scrollPosition={scrollPosition} setScrollPosition={setScrollPosition}/>
        <div style={{ width: '100vw', 
            height: isSmallScreen ? '100vh' : '100vh', 
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row', 
            alignItems: 'center',
            justifyContent: 'space-around',
            zIndex: 10
            }}>


        <div style={{display: 'flex', flexDirection: 'column', gap: '30px', color: 'white',  
        marginLeft: isSmallScreen ? '0px' : '0px',
        zIndex: 10  }}>
        <div style={{ display: 'flex', flexDirection: 'column', 
        gap: isSmallScreen ? '5px' : '15px', 
             marginLeft: isSmallScreen ? '0px' : '0px', 
             color: 'white' }} >
        <div className={nameClass} style={{ textAlign: 'center', fontSize: isSmallScreen ? '22px' : '40px', }}>
                Hi, I am Gavin,
            </div>
            <div className={nameClass} style={{ fontSize: isSmallScreen ? '22px' : '40px', textAlign: 'center' }}>
                A Software Engineer 
            </div>

            </div>
            </div>
            <img
                src={GavinM2}
                className={imageClass}
                style={{
                    marginTop: isSmallScreen ? '50px' : '0px',
                    width: isSmallScreen ? '210px' : '20vw',
                    maxWidth: '400px',
                    height: 'auto',
                    borderRadius: '10%',
                    marginRight: isSmallScreen ? '0px' : '10px',
                    zIndex: 10
                }}
            />
        


        </div>

        <div style={{ width: '100vw', height: '100vh',
             marginTop: isSmallScreen ? '100px' : '100px',
             }}>


        <div style={{ color: 'white', 
        fontSize: `${fontSize}px`, 
        height: isSmallScreen ? `${fontSize}px` : scrollPosition>3 ? '55px' : `${fontSize}px`,
        textAlign: 'center',
        zIndex: 10,
        position: 'relative'
            }}
            onMouseEnter={() => setshowToolBeltToolTip(true)}
            onMouseLeave={() => setshowToolBeltToolTip(false)}>
                <div style={{opacity: 1}}>
      Skills
      </div>
    </div>


<div style={{fontFamily: '"Helvetica Neue", Arial, sans-serif', 
    zIndex: 10,
    position: 'relative'
}}>

    <div style={{display: 'flex', width: '100vw', justifyContent: 'space-around', 
    marginTop: '40px', alignItems: 'center',
    zIndex: 10,
    position: 'relative'}}>


<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
<img
            src={pythonLogo}
            style={{
                borderRadius: '10px',
                width: 'auto', 
                height: isSmallScreen ? `${fontSize+20}px` : scrollPosition>2 ? '75px' : `${fontSize+20}px`,
                filter: 'invert(100%)',
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>Python</div>
        </div>


<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
<img
            src={javaLogo}
            style={{
                borderRadius: '5px',
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>Java</div>
</div>


<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
<img
            src={javascriptLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>Java Script</div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
<img
            src={cPlusPlusLogo}
            style={{
                width: 'auto', 
                height: `${fontSize+15}px`,
                filter: 'invert(100%)',
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>C++</div>
        </div>
        </div>

        <div style={{display: 'flex', width: '100vw', justifyContent: 'space-around' ,
         marginTop: '40px', alignItems: 'center'}}>


<div style={{ display: 'flex', alignItems: 'center' }}>
    <img
        src={appleIcon}
        alt="Apple Icon"
        style={{
            width: 'auto',
            height: isSmallScreen ? `${fontSize}px` : scrollPosition>2 ? '55px' : `${fontSize}px`,
        }}
    />
    <span style={{ 
        marginLeft: '10px', 
        fontFamily: '"Helvetica Neue", Arial, sans-serif', 
        color: 'white',
        fontSize: `${fontSize-10}px` 
    }}>
        iOS
    </span>
</div>

<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
        <img
            src={swiftLogo}
            style={{
                width: 'auto', 
                height: isSmallScreen? `${fontSize}px` : `${fontSize}px`, 
                filter: 'invert(100%)',
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>Swift</div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
<img
            src={androidLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>Android</div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
        <img
            src={kotlinLogo}
            style={{
                width: 'auto', 
                height: isSmallScreen ? `${fontSize-10}px` : scrollPosition>2 ? '45px' : `${fontSize-10}px`,
                borderRadius: '2px'
            }}
        />
                <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>Kotlin</div>
                </div>
                



</div>




<div style={{display: 'flex', width: '100vw', justifyContent: 'space-around' ,
         marginTop: '40px', alignItems: 'center'}}>

<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
<img
            src={awsLogo}
            style={{
                width: 'auto', 
                borderRadius: isSmallScreen ? '5px' : '10px',
                height: `${fontSize+20}px`,
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>Cloud</div>
        </div>


<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
<img
            src={reactLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
                filter: 'invert(100%)',
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>React</div>
        </div>



        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
<img
            src={htmlLogo}
            style={{
                width: 'auto', 
                borderRadius: '5px',
                height: isSmallScreen ? `${fontSize}px` : `${fontSize}px`,
                filter: 'invert(100%)',
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>html CSS</div>
        </div>



        

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
<img
            src={designIcon}
            style={{
                width: 'auto', 
                height: isSmallScreen ? `${fontSize}px` : scrollPosition>2 ? '55px' : `${fontSize}px`,
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>UI/UX</div>
        </div>




</div>







<div style={{display: 'flex', width: '100vw', justifyContent: 'space-around' ,
         marginTop: '40px', alignItems: 'center'}}>



        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
<img
            src={figmaLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`, 
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>Figma</div>
        </div>

<div style={{ display: 'flex', alignItems: 'center' }}>
    <img
        src={adobeLogo}
        alt="Apple Icon"
        style={{
            width: 'auto',
            height: isSmallScreen ? `${fontSize}px` : scrollPosition>2 ? '55px' : `${fontSize}px`,
        }}
    />
</div>


<div style={{display: 'flex', flexDirection: 'row', 
    alignItems: 'center', gap: '4px'}}>
<img
            src={bitcoin}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>Blockchain</div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
<img
            src={dockerLogo}
            style={{
                width: 'auto', 
                height: isSmallScreen ? `${fontSize}px` : `${fontSize}px`,
            }}
        />
        <div style={{color: 'white', fontSize: `${fontSize-10}px` }}>Docker</div>
        </div>

</div>

        </div>

        <div style={{marginTop: '150px'}}>
            <Education/>
            </div>


            <div style={{marginTop: '100px'}}>
        {isSmallScreen ? (
                <>
                


        <div style={{textAlign: 'center', fontSize: '35px', color: 'white',
                    zIndex: 10,
        position: 'relative'
        }}>
                Experience
            </div>

            <CompanySelector 
      changingScrollPosition={changingScrollPosition}/>

                </>
            ) : (
                <>
                
                <div style={{ display: 'flex', justifyContent: 'space-around'}}>


        <div>

            <div style={{textAlign: 'center', fontSize: '55px', color: 'white', 
                gap: '30px',
                zIndex: 10,
                position: 'relative'}}>
            Experience
            </div>
            <CompanySelector 
      changingScrollPosition={changingScrollPosition}/>
        </div>

        </div>

                </>
            )}
</div>

<div style={{height: '100vh', marginTop: '100px', display: 'flex', flexDirection: 'column',
    justifyContent: 'center', gap: '30px'
}}>
<Contact/>

<div style={{display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'center' : 'flex-start',
        gap: isMobile ? '30px': '120px',
        justifyContent: 'center',
        zIndex: 10,
        position: 'relative'}}>
      <a href="https://www.linkedin.com/in/gavin-d-milligan/" target="_blank" rel="noopener noreferrer">
        <img src={linkedin} style={{ width: '40px', height: 'auto', cursor: 'pointer' }} />
      </a>

      <a href="https://github.com/hashedonemilliontimes" target="_blank" rel="noopener noreferrer">
        <img src={github} style={{ width: '40px', height: 'auto', filter: 'invert(1)' }} />
      </a>

      <a href="https://www.instagram.com/gavmilligan/?hl=en" target="_blank" rel="noopener noreferrer">
        <img src={instagram} style={{ width: '40px', height: 'auto' }} />
      </a>
    </div>

        </div>
        </div>

    </div>
  );
}